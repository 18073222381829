import config from '../../../config'
import store from '../../store'

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async iniciarFormulario({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/initFormReservas?reserva_id=${payload.reserva_id}&origen=${payload.origen}&funcion_codigo=${payload.funcion_codigo}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al iniciar el formulario de Reservas: " + error.message
          })
        }
      })
    },
    async sincronizarTodas({}){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/getAllApiReservations`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al Sincronizar: " + error.message
          })
        }
      })
    },
    async buscarReservas({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/getReservas?reserva_id=${payload.reserva_id}&estado_id=${payload.estado_id}&cliente_nombre=${payload.cliente_nombre}&modelo_id=${payload.modelo_id}&retiro_id=${payload.retiro_id}&retiro_desde=${payload.retiro_desde}&retiro_hasta=${payload.retiro_hasta}&entrega_desde=${payload.entrega_desde}&entrega_hasta=${payload.entrega_hasta}&origen_id=${payload.origen_id}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          resolve(data);
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema en la Búsqueda de las reservas: " + error.message
          })
        }
      })
    },
    async sincronizarUnaReserva({}, payload){      
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/syncUpAReservation`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al sincrozinar la reserva: " + error.message
          })
        }
      })
    },
    async cancelarReserva({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/cancelarReserva`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al cancelar la reserva: " + error.message
          })
        }
      })
    },
    // /checkIn
    async checkIn({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/checkIn`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al grabar el Check-In: " + error.message
          })
        }
      })
    },// /checkOut
    async checkOut({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/checkOut`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al grabar el Check-Out: " + error.message
          })
        }
      })
    },// /preFactura
    async prefactura({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/preFactura`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al realizar la prefactura: " + error.message
          })
        }
      })
    },
    // comprobante_caja_id , reserva_id
    async anularPrefactura({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/cancelarPreFactura`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al anular la prefactura: " + error.message
          })
        }
      })
    },
    async cambiarEstadoReservaReabrir({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/cambiarEstadoReservaReabrir`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al cambiar el estado de la reserva (Reabrir): " + error.message
          })
        }
      })
    },
    async comprobarReapertura({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/radaRently/comprobarReapertura?reserva_id=${payload.reserva_id}&origen=${payload.origen}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          resolve(data)
        } catch (error) {
          resolve({
            resultado: 0,
            message: "Ocurrió un problema al comprobar condiciones de la reserva en el Index (comprobarReapertura): " + error.message
          })
        }
      })
    },
    // 05-02-2025 por MMURILLO, métodos para HQ
    async syncHQInfo({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPeticion = await fetch(`${config.BASE_URL}/adminRadaHQ/syncReservasTodas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sync = await syncPeticion.json()
          sync.msj = sync.message
          return resolve(sync)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncHQInfo: ' + error.message
          })
        }
      })
    },
    async syncResHQInfo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPeticion = await fetch(`${config.BASE_URL}/adminRadaHQ/syncReserva`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let sync = await syncPeticion.json()
          sync.msj = sync.message
          return resolve(sync)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncResHQInfo: ' + error.message
          })
        }
      })
    },
    async syncComplementaryData({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let syncPeticion = await fetch(`${config.BASE_URL}/adminRadaHQ/getCompletaDatos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sync = await syncPeticion.json()
          sync.msj = sync.message
          return resolve(sync)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo syncComplementaryData: ' + error.message
          })
        }
      })
    }
  }
}